.primary-500 {
  .chakra-switch__track[data-checked],
  .chakra-radio__control[data-checked] {
    background: #0968bf;
  }

  .chakra-radio__control[data-checked] {
    border-color: #0968bf;

    &::before {
      width: 8px;
      height: 8px;
    }
  }

  .chakra-checkbox__control {
    &[aria-checked='true'],
    &[data-checked],
    &[data-indeterminate],
    &[aria-checked='true']:hover,
    &[data-checked]:hover,
    &[aria-checked='true'][data-hover],
    &[data-checked][data-hover],
    &[aria-checked='mixed'] {
      background-color: #257ed0;
      border-color: #257ed0;
    }
  }
}

.vgs {
  #card-cvc iframe,
  #card-expiry iframe,
  #vgs-show iframe {
    height: 48px;
    width: 100%;
  }

  .credit-card-number-show iframe {
    height: 20px;
    width: 172px;
  }

  .credit-card-cvv-show iframe {
    height: 20px;
    width: 30px;
  }

  .vgs-copy iframe {
    height: 20px;
    width: 20px;
  }
}

.baselane-frequency-dropdown {
  padding: 12px !important;
  min-width: 120px;
  font-size: 14px; // md

  &.color-neutral-500 {
    color: #3a4b5b;
  }

  &.color-neutral-900 {
    color: #192c3e;
  }

  &.invalid {
    border-color: #c93a3a !important; // red.800AA
  }

  .react-dropdown-select-dropdown {
    right: 0;
    left: unset !important;
    width: unset !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 12px rgba(4, 39, 101, 0.12);
    border: none;
  }
}

.embla {
  padding: 1.6rem;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: auto;
  padding-bottom: 20px;
  padding-top: 8px;
  gap: 12px;
}

.embla__slide {
  flex: 0 0 calc(100% - 48px);
  min-width: 0;
  position: relative;
  display: flex;
  justify-content: center;
}

.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.embla__mobile__dots {
  z-index: 1;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.embla__dots {
  z-index: 1;
  bottom: 1.2rem;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.embla__dot {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  margin-right: 3px;
  margin-left: 3px;
}

.embla__dot--hidden {
  transition: all 0.2s ease;
  width: 0;
  height: 0;
  margin: 0;
}

.embla__dot:after {
  background: #d9d9d9;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  content: '';
}

.embla__dot--secondlast:after {
  background: #d9d9d9;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  content: '';
}

.embla__dot--last:after {
  background: #d9d9d9;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  content: '';
}

.embla__dot--selected:after {
  background: #257ed0;
  border-radius: 50%;
}

.embla__button {
  z-index: 1;
  color: var(--background-site);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}

.embla__button--prev {
  left: 1.6rem;
}

.embla__button--next {
  right: 1.6rem;
}

.embla__button:disabled {
  opacity: 0.3;
}

.embla__button__svg {
  width: 50%;
  height: 50%;
}

// Note: These properties can not be implemented in
// chakra-ui at the moment
#promptingScrollbar {
  height: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: fixed;
  z-index: 1;
  bottom: 24px;
  transition: all 0.111s ease;

  /* Firefox implementation */
  scrollbar-color: rgba(0, 0, 0, 0.5);
  scrollbar-width: auto;

  /* Forces scrollbar on webkit browsers */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 7px;
    transition: all 0.555s ease;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    transition: all 0.555s ease;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    border-radius: 3.5px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    transition: all 0.555s ease;
  }

  &:hover {
    height: 14px;
    transition: all 0.111s ease;
    &::-webkit-scrollbar {
      height: 12px;
      transition: all 0.555s ease;
    }
    &::-webkit-scrollbar-track {
      height: 12px;
      transition: all 0.555s ease;
    }
    &::-webkit-scrollbar-thumb {
      height: 12px;
      border-radius: 6px;
      transition: all 0.555s ease;
    }
  }
}

// Note: This is styling a non-chakra component
#statementDowloadButton {
  display: inline-flex;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 12px 0;
  align-self: flex-end;
  background: #0968bf;
  color: #ffffff;
  min-width: auto;

  &.hidden {
    visibility: hidden;
    margin: 0;
  }

  &:disabled {
    background: #e1f4ff !important;
    opacity: 1;
    cursor: default;
  }
  &:active {
    box-shadow: none;
    background: #0056af;
  }
  &:hover {
    background: #0968bf;
  }
  &:focus {
    box-sizing: 0 0 0 2px #9fd8ff;
  }
}

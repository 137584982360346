@mixin errorInput {
  border-color: #ea6868 !important;
  box-shadow: none;

  &:hover {
    border-color: #f09393 !important;
  }

  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible {
    border-color: #e04141 !important;
    box-shadow: 0px 0px 0px 2px #fce3e2 inset !important;
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    &:hover {
      border-color: #e04141 !important;
      box-shadow: 0px 0px 0px 2px #fce3e2 inset !important;
    }
  }
}

// Calendar
.react-datepicker-popper {
  z-index: 3 !important;

  &[data-placement^='top'] {
    margin-bottom: 6px !important;
    padding: 0;
  }

  &[data-placement^='bottom'] {
    margin-top: 6px !important;
    padding: 0;
  }

  .react-datepicker {
    font-family: 'Ease Variable', Helvetica, Arial;
    background-color: #ffffff;

    &.baselane-datepicker-calendar {
      padding: 16px;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      box-shadow: -4px 8px 24px 0px rgba(219, 219, 224, 0.2);
      border: 1px solid #dadfe6;

      // issue regarding aria live always showing
      // https://github.com/Hacker0x01/react-datepicker/issues/3924
      .react-datepicker__aria-live {
        position: absolute;
        clip-path: circle(0);
        border: 0;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
        white-space: nowrap;
      }

      .react-datepicker__month-container {
        .react-datepicker__header {
          border: 0;
          padding: 0;
          background-color: #fff;

          &.react-datepicker__header--custom {
            position: inherit !important;

            .react-datepicker__day-names {
              padding: 0;
              background: #fff;
              display: flex;
              gap: 4px;

              .react-datepicker__day-name {
                color: #192c3e;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                width: 40px;
                height: 32px;
                margin: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                &::selection {
                  background: #0968bf;
                }
              }
            }
          }
        }

        .react-datepicker__month {
          margin: 4px 0;
          padding: 0;

          .react-datepicker__week {
            display: flex;
            gap: 4px;

            &:not(:first-child) {
              padding-top: 4px;
            }

            .react-datepicker__day {
              width: 40px;
              height: 40px;
              background-color: #fff;
              color: #192c3e;
              font-size: 14px;
              line-height: 20px;
              border-radius: 6px;
              border: 1px solid #fff;
              align-items: center;
              justify-content: center;
              display: inline-flex;
              margin: 0;
              font-weight: 100;

              &::selection {
                background: #0968bf;
              }

              &:hover {
                background-color: #e1f4ff;
                border-color: #e1f4ff;
                color: #192c3e;
              }

              &:active {
                background-color: #bee5ff;
                border-color: #bee5ff;
                color: #192c3e;

                &:focus {
                  background-color: #bee5ff;
                  border-color: #bee5ff;
                }
              }

              &:focus {
                outline: dashed 1.5px #0968bf;
                outline-offset: 2px;
                color: inherit;
              }

              &.react-datepicker__day--disabled {
                color: #6c7884;

                &:hover,
                &:active,
                &:focus {
                  background-color: #fff;
                  color: #6c7884;
                  border-color: #fff;
                  outline: none;
                }
              }

              &.react-datepicker__day--today {
                border-color: #bee5ff;
                font-weight: 100;

                &:hover {
                  background-color: #e1f4ff;
                  border-color: #bee5ff;
                }

                &:active {
                  background-color: #bee5ff;
                  border-color: #bee5ff;

                  &:focus {
                    background-color: #bee5ff;
                    border-color: #bee5ff;
                  }
                }

                &:focus {
                  border-color: #0968bf;
                }

                &.react-datepicker__day--disabled {
                  border-color: #e7e9eb;
                  color: #d4d8dc;
                }
              }

              &.react-datepicker__day--in-range {
                background-color: #e1f4ff;
                border-color: #e1f4ff;
                font-weight: 400;
                display: flex;
              }

              &.react-datepicker__day--selected,
              &.react-datepicker__day--range-start,
              &.react-datepicker__day--range-end,
              &.baselane-day-selected-start {
                background-color: #012550;
                border-color: #012550;
                font-weight: 500;
                color: #fff;

                &:hover {
                  background-color: #10325b;
                  border-color: #10325b;
                  color: #fff;
                }

                &:active {
                  background-color: #012550;
                  border-color: #012550;
                  color: #fff;

                  &:focus {
                    background-color: #012550;
                    border-color: #012550;
                    color: #fff;
                  }
                }

                &.react-datepicker__day--disabled {
                  background-color: #e7e9eb;
                  border-color: #e7e9eb;
                  color: #d4d8dc;

                  &:hover,
                  &:active,
                  &:focus {
                    background-color: #e7e9eb;
                    color: #d4d8dc;
                    border-color: #e7e9eb;
                    outline: none;
                  }
                }
              }

              &.react-datepicker__day--outside-month {
                background-color: #fff;
                border-color: transparent;
                color: transparent;
                pointer-events: none;

                &::selection {
                  background: #fff;
                }

                &:hover,
                &:active,
                &:focus {
                  background-color: #fff;
                  border-color: transparent;
                  color: transparent;
                  pointer-events: none;
                  outline: none;
                }
              }
            }
          }
        }
      }

      .react-datepicker__children-container {
        width: 100%;
        margin: 0;
      }
    }
  }
}

// Applies to Range Picker in Modal Only
.chakra-modal__body.range-picker-modal {
  .react-datepicker-popper {
    transform: none !important;

    .react-datepicker {
      &.range-datepicker-modal-calendar {
        width: 324px !important;
        height: 434px !important;
        padding: 10px 0 !important;
        border: none;
        box-shadow: none;
        position: absolute;
        top: 72px;
        left: 16px;
        right: 16px;

        .react-datepicker__day-name,
        .react-datepicker__day {
          width: 42.75px !important;
          min-width: 42.75px !important;
        }
      }
    }
  }
}

// Inputs
.react-datepicker-wrapper {
  width: 100%;
  margin: 0 !important;

  .react-datepicker__input-container {
    input {
      &.baselane-datepicker-input {
        border-radius: 6px;
        padding: 12px 16px;
        border: 1px solid #dadfe6;
        background-color: #fff;
        font-size: 14px;
        line-height: 20px;
        color: #000619;
        font-weight: 100;

        &.has-error {
          @include errorInput();
        }

        &.react-datepicker-ignore-onclickoutside {
          border-color: #257ed0;
          box-shadow: none;
          color: #000619;

          &:hover,
          &:active,
          &:focus,
          &:focus-within,
          &:focus-visible {
            &:not(.table-cell) {
              border-color: #257ed0 !important;
              color: #000619;
            }
          }

          &:active,
          &:focus,
          &:focus-within,
          &:focus-visible {
            box-shadow: 0px 0px 0px 2px #e1f4ff inset;
          }

          &.has-error {
            @include errorInput();
          }
        }

        &::placeholder {
          color: #6c7884;
        }

        &::selection {
          color: #000619;
          background: #0968bf;
        }

        &:hover {
          border-color: #acb8c7;
          color: #000619;

          &.has-error {
            @include errorInput();
          }
        }

        &:active,
        &:focus,
        &:focus-within {
          border-color: #257ed0;
          box-shadow: 0px 0px 0px 2px #e1f4ff inset;
          color: #000619;

          &.has-error {
            @include errorInput();
          }
        }

        &:disabled {
          border-color: #f0f2f6;
          background-color: #f0f1f3;
          color: #6c7884;
          box-shadow: none;
          opacity: 1;

          &:active,
          &:focus,
          &:focus-within {
            border-color: #f0f2f6;
            background-color: #f0f1f3;
            color: #6c7884;
            box-shadow: none;
          }

          &::placeholder {
            color: #6c7884;
          }
        }

        &.w-200 {
          width: 200px;
        }
      }

      &.table-cell {
        border: 1px solid transparent !important;
        padding: 2px 4px !important;
        background: transparent !important;
        color: #192c3e !important; /* neutral.700 */
        font-size: 14px !important;
        z-index: 0;
        margin: 0 !important;

        &:hover {
          border-color: transparent !important;
        }
      }

      &.read-only-input {
        border-radius: 6px;
        padding: 12px 16px;
        border: 1px solid #dadfe6;
        background-color: #fff;
        font-size: 14px;
        line-height: 20px;
        color: #000619;

        &.has-error {
          @include errorInput();
        }

        &.react-datepicker-ignore-onclickoutside {
          border-color: #257ed0;
          box-shadow: none;
          color: #000619;

          &:hover,
          &:active,
          &:focus,
          &:focus-within,
          &:focus-visible {
            border-color: #257ed0 !important;
            color: #000619;
          }

          &:active,
          &:focus,
          &:focus-within,
          &:focus-visible {
            box-shadow: 0px 0px 0px 2px #e1f4ff inset;
          }

          &.has-error {
            @include errorInput();
          }
        }

        &::placeholder {
          color: #6c7884;
        }

        &::selection {
          color: #000619;
          background: #0968bf;
        }

        &:hover {
          border-color: #acb8c7;
          color: #000619;

          &.has-error {
            @include errorInput();
          }
        }

        &:active,
        &:focus,
        &:focus-within {
          border-color: #257ed0;
          box-shadow: 0px 0px 0px 2px #e1f4ff inset;
          color: #000619;

          &.has-error {
            @include errorInput();
          }
        }

        &:disabled {
          border-color: #f0f2f6;
          background-color: #f0f1f3;
          color: #6c7884;
          box-shadow: none;
          opacity: 1;

          &:active,
          &:focus,
          &:focus-within {
            border-color: #f0f2f6;
            background-color: #f0f1f3;
            color: #6c7884;
            box-shadow: none;
          }

          &::placeholder {
            color: #6c7884;
          }
        }

        &.w-200 {
          width: 200px;
        }
      }
    }
  }

  & + .react-datepicker__tab-loop {
    margin: 0 !important;
  }
}

.baselane-datepicker-input {
  &.table-cell {
    border: 1px solid transparent !important;
    padding: 2px 4px !important;
    background: transparent !important;
    color: #192c3e !important; /* neutral.700 */
    font-size: 14px !important;
    z-index: 0;
    margin: 0 !important;

    &:hover {
      border: 1px solid transparent !important;
    }
  }

  &.read-only-input {
    border-radius: 6px;
    padding: 12px 16px;
    border: 1px solid #dadfe6;
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    color: #000619;

    &.has-error {
      @include errorInput();
    }

    &.react-datepicker-ignore-onclickoutside {
      border-color: #257ed0;
      box-shadow: none;
      color: #000619;

      &:hover,
      &:active,
      &:focus,
      &:focus-within,
      &:focus-visible {
        border-color: #257ed0;
        color: #000619;
      }

      &:active,
      &:focus,
      &:focus-within,
      &:focus-visible {
        box-shadow: 0px 0px 0px 2px #e1f4ff inset;
      }

      &.has-error {
        @include errorInput();
      }
    }

    &::placeholder {
      color: #6c7884;
    }

    &::selection {
      color: #000619;
      background: #0968bf;
    }

    &:hover {
      border-color: #acb8c7;
      color: #000619;

      &.has-error {
        @include errorInput();
      }
    }

    &:active,
    &:focus,
    &:focus-within {
      border-color: #257ed0;
      box-shadow: 0px 0px 0px 2px #e1f4ff inset;
      color: #000619;

      &.has-error {
        @include errorInput();
      }
    }

    &:disabled {
      border-color: #f0f2f6;
      background-color: #f0f1f3;
      color: #6c7884;
      box-shadow: none;
      opacity: 1;

      &:active,
      &:focus,
      &:focus-within {
        border-color: #f0f2f6;
        background-color: #f0f1f3;
        color: #6c7884;
        box-shadow: none;
      }

      &::placeholder {
        color: #6c7884;
      }
    }

    &.w-200 {
      width: 200px;
    }
  }
}

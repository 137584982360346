.isHovered {
  input,
  .react-dropdown-select.border-on-hover {
    border: 1px solid #dadfe6;
    background-color: white;

    .subcategory {
      display: none;
    }

    .icon-carret {
      visibility: visible !important;
      display: flex;
    }

    .placeholder-container {
      color: #6c7884;
    }

    .clear {
      display: flex;
    }
  }
}

.clear-on-hover {
  .clear {
    display: none;
  }

  .icon-carret {
    display: none;
  }
}

.carousel {
  width: 100%;
  position: relative;
  margin-top: 8px;
}

@media (min-width: 750px) {
  .carousel {
    margin-left: auto;
    margin-right: auto;
    max-width: 67rem;
  }
}

.carousel__container {
  position: relative;
  background-color: var(--background-code);
}

@media (max-width: 749px) {
  .carousel__container {
    border-top: 0.1rem solid var(--detail-low-contrast);
    border-bottom: 0.1rem solid var(--detail-low-contrast);
  }
}

@media (min-width: 750px) {
  .carousel__container {
    border-radius: 0.4rem;
    border: 0.1rem solid var(--detail-low-contrast);
  }
}

$border-color: #dadfe6; // brand.darkBlue.200
$color: #192c3e; // neutral.700

$hover-border-color: #acb8c7; // brand.darkBlue.300
$focus-border-color: #257ed0; // brand.blue.700
$focus-box-shadow: 0 0 0 2px #e1f4ff inset; // brand.blue.100

$disabled-background: #f0f1f3; // neutral.150
$disabled-color: #9aa3ab; // neutral.400
$disabled-border-color: #d4d8dc; // neutral.250

$invalid-border: 1px solid #ea6868; // red.500
$invalid-action-border: 1px solid #f09393; // red.400
$invalid-box-shadow: 0 0 0 2px #fce3e2 inset; // red.200

:export {
  borderColor: $border-color;
  color: $color;
  hoverBorderColor: $hover-border-color;
  focusBorderColor: $focus-border-color;
  focusBoxShadow: $focus-box-shadow;
  disabledBackground: $disabled-background;
  disabledColor: $disabled-color;
  disabledBorderColor: $disabled-border-color;
  invalidBorder: $invalid-border;
  invalidActionBorder: $invalid-action-border;
  invalidBoxShadow: 0 0 0 2px #fce3e2 inset;
}

.new.react-dropdown-select {
  min-width: 75px;
  width: 135px;
  height: 32px;
  min-height: 32px !important;
  padding: 0 !important;
  border-radius: 6px !important;
  color: $color;
  border-color: $border-color !important;
  font-size: 12px;
  line-height: 20px;
  background-color: #fff;

  &.border-on-hover {
    border-color: transparent !important;
  }

  &.carret-on-hover {
    .icon-carret {
      visibility: hidden;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      .icon-carret {
        visibility: visible !important;
      }
    }
  }

  &[disabled] {
    background: $disabled-background !important; // neutral.150
    color: $disabled-color !important; // neutral.400
    border-color: $disabled-border-color !important; // neutral.250
    opacity: 1 !important;
    cursor: default;
  }

  &:hover {
    border-color: $hover-border-color !important; // brand.darkBlue.300
  }

  &:focus,
  &:focus-within {
    border-color: $focus-border-color !important; // brand.blue.700
    box-shadow: $focus-box-shadow !important;
  }

  &:active {
    border-color: $hover-border-color !important; // brand.darkblue.300
    box-shadow: none !important;

    .placeholder {
      color: #010113 !important; // neutral.900
    }
  }

  &.tier1-title-dropdown,
  &.tier2-dropdown,
  &.date-dropdown {
    max-width: 170px;
    width: 170px;
  }

  &.auto-select-input-width {
    max-width: unset;
    width: auto;
  }

  &.date-dropdown {
    .react-dropdown-select-dropdown {
      height: 644px;
      min-height: 644px;
    }
  }

  &.auto-width {
    width: auto;
  }

  &.hide-down-arrow {
    .react-dropdown-select-dropdown-handle {
      display: none;
    }
  }

  &.disable-max-width {
    max-width: unset;
  }

  &.input-form-lg {
    height: 48px;
  }

  &.input-form-md {
    height: 40px;
  }

  &.input-form-sm {
    height: 32px;
  }

  &.is-full-width {
    width: 100%;
    max-width: 100%;
  }

  &.input-invalid {
    border: $invalid-border !important;
    box-shadow: none !important;

    &:hover {
      border: $invalid-action-border !important;
      box-shadow: none;
    }

    &:focus,
    &:focus-within {
      border: $invalid-action-border !important;
      box-shadow: $invalid-box-shadow !important;
    }
  }

  .react-dropdown-select-content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-align: left;

    &:hover {
      cursor: pointer;
    }
  }

  .react-dropdown-select-dropdown-handle {
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      path {
        stroke: none;
      }
    }
  }
}

@mixin select-dropdown {
  border: none !important;
  border-radius: 0 0 6px 6px !important;
  cursor: default;

  .element {
    &:focus-within {
      outline: none;
    }
  }
}

.portal-width-dropdown {
  > div {
    height: 100%;

    .react-dropdown-select-dropdown {
      @include select-dropdown;
      width: 100%;
      position: absolute;
      box-shadow: none !important;
      top: 0;
      left: 0;
      height: calc(100% - 16px) !important;
      min-height: 100% !important;
    }
  }
}

.new.auto-width-dropdown {
  .react-dropdown-select-dropdown {
    @include select-dropdown;
    width: 100%;
  }

  &.select-auto-width {
    width: auto;
    max-width: 100%;
  }

  &.auto-height {
    .react-dropdown-select-dropdown {
      @include select-dropdown;
      height: auto !important;
      min-height: unset !important;
      max-height: unset !important;
    }
  }
}

@mixin fixed-w-dropdown($widthSize) {
  .react-dropdown-select-dropdown {
    width: $widthSize;
    @include select-dropdown;
  }

  &.select-auto-width {
    width: auto;
  }

  &.auto-height {
    .react-dropdown-select-dropdown {
      width: $widthSize;
      @include select-dropdown;
      height: auto !important;
      min-height: unset !important;
      max-height: unset !important;
    }
  }
}

// Placing it outside the parent to make it available for portal
.fixed-width-dropdown {
  @include fixed-w-dropdown(330px);
}

// these transitions are tuned to match
// the open/close of the custom categories dropdown area
// in the category filter
.dropdown-open-transition {
  transition: all 0.31s ease-in;
}

.dropdown-close-transition {
  transition: all 0.16s ease-out;
}

// used for rocket lawyer
.card-header {
  cursor: pointer;
  border: none !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 14px;
  line-height: 20px;
  color: #acb8c7;
  background: #fafbfd;
  border-bottom: 1px solid #f0f2f6 !important;

  &:hover {
    background: #ecf9ff;
    border-bottom-color: #e1f4ff !important;
  }

  &:focus,
  &:focus-within {
    background: #ecf9ff;
    border-bottom-color: #9fd8ff !important;
    box-shadow: none !important;
  }

  &[disabled] {
    cursor: default;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: #f0f1f3 !important; // neutral.150
    color: #9aa3ab !important; // neutral.400
    opacity: 1 !important;
    border: 1px solid #d4d8dc !important; // neutral.250

    &:hover,
    &:focus,
    &:focus-within {
      border: 1px solid #d4d8dc !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      background: #f0f1f3 !important;
      color: #9aa3ab !important;
      opacity: 1 !important;
    }
  }

  .react-dropdown-select-dropdown {
    width: calc(100% + 2px);
  }
}

.react-dropdown-select.amountInput {
  cursor: pointer;
  border: 0 !important;
  border-radius: 0px !important;
  font-size: 14px;
  line-height: 20px;
  color: #010113;
  background: transparent;
  padding: 12px !important;
  width: 100% !important;
  box-sizing: border-box;

  .react-dropdown-select-content {
    padding-right: 8px;
    min-width: 100px;
    box-sizing: border-box;
    max-width: 100px;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: none !important;
    color: #010113 !important;
    opacity: 1 !important;
    box-shadow: none !important;
  }

  .react-dropdown-select-dropdown {
    @include select-dropdown;
  }
}

.react-dropdown-select.repeat-every {
  cursor: pointer;
  border: 0 !important;
  border-radius: 0px !important;
  font-size: 14px;
  line-height: 20px;
  color: #010113;
  background: transparent;
  padding: 0 !important;
  min-width: 100% !important;
  box-sizing: border-box;

  .react-dropdown-select-content {
    padding-right: 8px;
    box-sizing: border-box;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: none !important;
    color: #010113 !important;
    opacity: 1 !important;
    box-shadow: none !important;
  }

  .react-dropdown-select-dropdown {
    @include select-dropdown;
  }
}

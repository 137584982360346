.kycForm-datepicker {
  padding: 12px 16px !important;
  height: 48px !important;
  width: 100% !important;
  font-size: 14px !important;
  border: 1px solid #c8d0da !important;
  border-radius: 6px !important;
  line-height: 20px !important;
  color: #6c7884 !important;

  &:hover {
    color: #3a4b5b !important;
    border-color: #acb8c7 !important;
  }

  &:focus {
    color: #192c3e !important;
    border-color: #6c7884 !important;
  }

  &:disabled {
    color: #acb8c7 !important;
    border-color: #e5e9ef !important;
  }

  &.has-error,
  &:focus.has-error,
  &:hover.has-error {
    border: 1px solid #c93a3a !important;
  }
}

// https://www.verygoodsecurity.com/docs/vgs-collect/js/faq#whats-available-countries-for-postal_code-validation
.vgs_collect_field {
  iframe {
    width: 100%;
  }
}

.react-dropdown-select {
  min-width: 75px;
  width: 135px;
  height: 32px;
  min-height: 32px !important;
  padding: 0 !important;
  border-radius: 6px !important;
  color: #192c3e;
  border-color: #e5e9ef !important;
  font-size: 12px;
  line-height: 20px;
  background-color: #fff;

  .placeholder {
    color: #3a4b5b;
    font-size: 12px;
    line-height: 20px;
  }

  &.medium-font {
    .placeholder {
      font-size: 14px;
    }
  }

  &.border-on-hover {
    border-color: transparent !important;
  }

  &.carret-on-hover {
    .icon-carret {
      visibility: hidden;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      .icon-carret {
        visibility: visible !important;
      }
    }
  }

  &[disabled] {
    border-color: #c8d0da !important;
    background-color: #f0f2f6 !important;
    color: #3a4b5b !important;
    opacity: 1 !important;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border-color: #c8d0da !important;

    .placeholder {
      color: #3a4b5b !important;
    }
  }

  &:focus,
  &:focus-within {
    box-shadow: inset 0px 0px 1px 1px #e1f4ff !important;
  }

  &:active {
    border-color: #6c7884 !important;
    box-shadow: none !important;

    .placeholder {
      color: #192c3e !important;
    }
  }

  &.hide {
    display: none;
  }

  &.tier1-title-dropdown,
  &.tier2-dropdown,
  &.date-dropdown {
    max-width: 160px;
    width: 160px;
  }

  &.auto-select-input-width {
    max-width: unset;
    width: auto;
  }

  &.date-dropdown {
    .react-dropdown-select-dropdown {
      height: 644px;
      min-height: 644px;
    }
  }

  &.auto-width {
    width: auto;
  }

  &.hide-down-arrow {
    .react-dropdown-select-dropdown-handle {
      display: none;
    }
  }

  &.hide-dropdown {
    height: 40px;
    width: auto;
    min-width: auto;
    max-width: unset;

    .react-dropdown-select-dropdown {
      display: none;
    }
  }

  &.disable-max-width {
    max-width: unset;
  }

  &.input-form-xl {
    height: 48px;
  }

  &.input-form-lg {
    height: 40px;
  }

  &.input-form-md {
    height: 32px;
  }

  &.input-invalid {
    border: 1px solid #c93a3a !important;
  }

  &.input-border-neutral-400 {
    border: 1px solid #6c7884 !important;

    .placeholder {
      color: #c8d0da;
    }
  }

  &.fontSize-sm {
    font-size: 14px;

    .placeholder {
      font-size: 14px;
    }
  }

  &.placeholder-font-color-neutral-400 {
    .placeholder {
      color: #6c7884 !important;
    }
  }

  &.fontSize-xs {
    font-size: 12px;

    .placeholder {
      font-size: 12px;
    }
  }

  .react-dropdown-select-content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-align: left;

    &:hover {
      cursor: pointer;
    }
  }

  .react-dropdown-select-dropdown-handle {
    height: 100%;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      path {
        stroke: none;
      }
    }
  }
}

@mixin select-dropdown {
  height: 580px;
  min-height: 580px;
  box-shadow: 0 4px 12px rgba(4, 39, 101, 0.12) !important;
  border: none !important;
  border-radius: 0 0 6px 6px !important;
  cursor: default;

  .element {
    &:focus-within {
      outline: none;
    }
  }
}

.portal-width-dropdown {
  > div {
    height: 100%;

    .react-dropdown-select-dropdown {
      @include select-dropdown;
      width: 100%;
      position: relative;
      box-shadow: none !important;
      top: 0;
      left: 0;
      height: calc(100% - 16px) !important;
      min-height: 100% !important;
    }
  }
}

.auto-width-dropdown {
  .react-dropdown-select-dropdown {
    @include select-dropdown;
  }

  &.select-auto-width {
    width: auto;
    max-width: 100%;
  }

  &.auto-height {
    .react-dropdown-select-dropdown {
      @include select-dropdown;
      height: auto !important;
      min-height: auto !important;
    }
  }
}

// Placing it outside the parent to make it available for portal
.fixed-width-dropdown {
  .react-dropdown-select-dropdown {
    width: 330px;
    @include select-dropdown;
  }

  &.select-auto-width {
    width: auto;
  }

  &.auto-height {
    .react-dropdown-select-dropdown {
      width: 330px;
      @include select-dropdown;
      height: auto !important;
      min-height: auto !important;
    }
  }
}

.baselane-dropdown-wrapper {
  width: 100%;
  .full-width-dropdown {
    border: solid 1px red;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    .react-dropdown-select-dropdown {
      @include select-dropdown;
      height: auto !important;
      min-height: auto !important;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }
}

// these transitions are tuned to match
// the open/close of the custom categories dropdown area
// in the category filter
.dropdown-open-transition {
  transition: all 0.31s ease-in;
}

.dropdown-close-transition {
  transition: all 0.16s ease-out;
}

.card-header {
  cursor: pointer;
  border: none !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-size: 14px;
  line-height: 20px;
  color: #3a4b5b;
  background: #fafbfd;
  border-bottom: 1px solid #f0f2f6 !important;

  &:hover {
    background: #ecf9ff;
    border-bottom-color: #e1f4ff !important;
  }

  &:focus,
  &:focus-within {
    background: #ecf9ff;
    border-bottom-color: #9fd8ff !important;
    box-shadow: none !important;
  }

  &[disabled] {
    cursor: default;
    border: none !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: #fafbfd !important;
    color: #3a4b5b !important;
    opacity: 1 !important;

    &:hover,
    &:focus,
    &:focus-within {
      border: none !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      background: #fafbfd !important;
      color: #3a4b5b !important;
      opacity: 1 !important;
    }
  }

  .react-dropdown-select-dropdown {
    width: calc(100% + 2px);
  }
}

.mobile-variant {
  &.react-dropdown-select {
    min-width: 100% !important;
    padding: 10px 16px !important;
    height: auto;

    .react-dropdown-select-content > * {
      padding: 0 !important;
    }

    .icon-carret {
      padding: 0 !important;
    }

    .placeholder {
      font-size: 14px !important;
      line-height: 24px !important;
    }

    .react-dropdown-select-dropdown {
      width: 100%;
      @include select-dropdown;
      height: auto !important;
      max-height: 220px !important;
      min-height: 220px !important;
    }
  }
}

.chakra-checkbox {
  .chakra-checkbox__control {
    border-radius: 4px;
    border-width: 1px;
    border-color: #6c7884;
    background-color: white;
  }

  .chakra-checkbox__label {
    overflow: hidden;
  }

  &.neutral-900 {
    .chakra-checkbox__control {
      border-color: #192c3e;
    }
  }

  &.neutral-200 {
    .chakra-checkbox__control {
      border-color: #c8d0da;
    }
  }
}

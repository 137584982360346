// handler where you click to open the datepicker dropdown
.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    input {
      cursor: text;
      display: inline-block;
      width: 100%;
      background-color: transparent;
      font-size: 12px;
      color: #192c3e;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #e5e9ef;
      padding: 6px 12px;
      height: 32px;

      &.w-130 {
        width: 130px;
      }

      &.w-200 {
        width: 200px;
      }

      &.text-center {
        text-align: center;
      }

      &:-internal-autofill-selected {
        background-color: #fff !important;
      }

      &::placeholder {
        color: #6c7884;
      }

      &:hover {
        color: #3a4b5b;
        border: 1px solid #c8d0da !important;
        background-color: #fff;
      }

      &:focus,
      &:focus-within {
        color: #3a4b5b;
        border: 1px solid #c8d0da;
        box-shadow: inset 0px 0px 1px 1px #e1f4ff;
        outline: none;
      }

      &.has-error,
      &:focus.has-error,
      &:hover.has-error {
        border: 1px solid #c93a3a;
      }

      &.hide-border {
        border: 1px solid transparent !important;
      }

      &.lg {
        height: 40px;
        font-size: 14px;
        line-height: 24px;
      }

      &.xl {
        height: 48px;
        font-size: 14px;
        line-height: 24px;
        padding: 12px 16px;
      }

      &.has-error,
      &:focus.has-error,
      &:hover.has-error {
        border: 1px solid #c93a3a;
      }

      &:read-only {
        color: #c8d0da;
      }

      &:disabled {
        color: #acb8c7;
        border: 1px solid #f0f2f6 !important;

        &::placeholder {
          color: #acb8c7;
        }

        &:hover {
          cursor: default;
        }
      }
    }
  }
}

// datepicker dropdown
.react-datepicker__tab-loop {
  .react-datepicker-popper {
    z-index: 1301;
  }
}

@mixin has-border {
  border: 1px solid #c8d0da;
  border-radius: 4px;
  box-shadow: none;
}

@mixin no-border {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(4, 39, 101, 0.12);
}

.react-datepicker {
  @include no-border();

  .react-datepicker__triangle {
    border-top-color: #fff;
    border-bottom-color: #192c3e;

    &::before {
      border-top-color: #fff;
      border-bottom-color: #192c3e;
    }
  }

  .react-datepicker__navigation {
    margin-top: 7px;
    border-color: #acb8c7;
    border-width: 0 2px 2px 0;
  }

  .react-datepicker__navigation--previous {
    margin-left: 16px;
  }

  .react-datepicker__navigation--next {
    margin-right: 16px;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: #001843;
      border: 0;
      padding: 0;

      .react-datepicker__current-month {
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        padding: 12px;
        margin-top: -2px;
      }

      .react-datepicker__day-names {
        background-color: #fff;
        border: 0;
        color: #002e51;
        float: left;
        width: 100%;
        padding: 16px 24px 0;

        .react-datepicker__day-name {
          color: #002e51;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          width: 32px;
          height: 32px;
          align-items: center;
          justify-content: center;
          display: inline-flex;
          margin: 0;

          &:not(:first-child) {
            margin-left: 4px;
          }
        }
      }
    }

    .react-datepicker__month {
      float: left;
      padding: 8px 24px 14px;
      margin: 0;

      .react-datepicker__week {
        &:not(:first-child) {
          padding-top: 8px;
        }

        .react-datepicker__day,
        .react-datepicker__time-name {
          border-radius: 50%;
          color: #758ea1;
          width: 32px;
          height: 32px;
          font-size: 12px;
          line-height: 20px;
          align-items: center;
          justify-content: center;
          display: inline-flex;
          margin: 0;

          &:not(:first-child) {
            margin-left: 4px;
          }

          &:hover {
            background-color: #fafbfd;
            color: #3a4b5b;
          }

          &:focus,
          &:focus-visible {
            outline: 1px solid #e1f4ff;
            background-color: transparent;
            color: #3a4b5b;
          }

          &:active {
            background-color: #f0f2f6;
            color: #3a4b5b;

            &:focus {
              outline: none;
              background-color: #f0f2f6;
            }
          }

          &.react-datepicker__day--keyboard-selected {
            background: transparent;
          }

          &.react-datepicker__day--in-range,
          &.react-datepicker__day--in-selecting-range {
            background-color: #e1f4ff;
            color: #3a4b5b;
          }

          &.react-datepicker__day--selected,
          &.react-datepicker__day--range-start,
          &.react-datepicker__day--range-end {
            background-color: #257ed0;
            color: #fff;

            &:active {
              background-color: #257ed0;
              color: #fff;
            }
          }

          &.react-datepicker__day--disabled {
            color: #e5e9ef;
            background-color: transparent;
            outline: none;
            cursor: not-allowed;

            &:hover,
            &:active,
            &:focus,
            &:focus-visible {
              color: #e5e9ef;
              background-color: transparent;
              outline: none;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.has-border {
  .react-datepicker {
    @include has-border();

    .react-datepicker__header {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      position: relative;
      top: 2px;
    }
  }
}

// Note: overriding video size here as the default style does
// not match viewport currently
video#mitekMedia {
  /* 
    // This is the default style generated by the
    // SDK for reference, where the scale factor is calculated somehow
    // but does not seem to match the viewport (in storybook at least)

        margin: 0px; 
        padding: 0px; 
        position: absolute; 
        object-fit: contain; 
        transform-origin: 50% 50%; 
        top: 50%; 
        left: 50%; 
        pointer-events: none; 
        transform: translate(-50%, -50%) scale(0.5208333333333334); 
    */
  transform: translate(-50%, -50%) !important;
}

// TODO: Once Rent Collection in Mobile's enabled, delete this file and use shared/styles/reactDatepicker.scss
.form-datepicker {
  padding: 12px 16px !important;
  height: 48px !important;
  width: 100% !important;
  border: 1px solid #e5e9ef !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: 24px !important;

  &.has-error,
  &:focus.has-error,
  &:hover.has-error {
    border: 1px solid #c93a3a !important;
  }
}

#manual-trx-date.form-datepicker {
  height: 40px !important;
}

/* This removes the default close button background on hover */
[aria-label='Close']:hover {
  background: transparent !important;
}

#vouched-element .progress-content.theme-color {
  margin-top: 50%;
}

#vouched-element .vouched-description {
  margin-bottom: 8px;
}

#vouched-element #vouchedIDDirections,
#vouched-element .step-content,
#vouched-element,
.step-content,
#vouchedIDDirections [step-content] {
  background: none !important;
}

#vouched-element {
  width: 100%;
}

#vouched-element .verbose-content .verbose-step-content {
  height: auto !important;
}

#vouched-element .cameraErrorText a {
  color: #40a1ed;
}

#vouched-element .verbose-footer-content .verbose-button-content {
  font-size: calc(1vw + 1vh + 0.5vmin) !important;
}

@media print {
  .ada-button-frame {
    display: none !important;
  }
}

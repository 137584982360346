.onboarding-box {
  #onboarding-box-header {
    color: #257ed0;
  }

  #onboarding-box-body {
    color: #3a4b5b;
    #onboarding-box-body-header {
      color: #257ed0;
    }
  }

  #onboarding-box-time {
    color: #acb8c7;
  }

  .hovered {
    color: #fff !important;
    #onboarding-box-body-header {
      color: #fff !important;
    }
  }
}

.onboarding-box .disabled {
  #onboarding-box-header {
    color: #acb8c7;
  }
}

:root {
  --pseudo-divider-color-completed: #7fa6f8;
  --pseudo-divider-color-notCompleted: #9fd8ff;
}

@mixin divider {
  content: '';
  width: 1px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 3px;
  z-index: 1;
}

@mixin roundedBorderRightRadius {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

.atom {
  &:after {
    @include divider();
    border-right: solid 1px var(--pseudo-divider-color-notCompleted);
  }

  &:hover {
    @include roundedBorderRightRadius();

    &:after {
      @include divider();
      border-right: solid 1px transparent;
    }
  }

  &-completed {
    &:after {
      @include divider();
      border-right: solid 1px var(--pseudo-divider-color-completed);
    }

    &:hover {
      @include roundedBorderRightRadius();

      &:after {
        @include divider();
        border-right: solid 1px transparent;
      }
    }
  }

  &-before-active {
    @include roundedBorderRightRadius();

    &:hover {
      @include roundedBorderRightRadius();
    }

    &:after {
      @include divider();
      border-right: solid 1px transparent;
    }
  }
}
